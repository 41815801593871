import React from "react";
import i18next from "i18next";
import { EntityPage } from "../../_components/EntityPage/EntityPage";
import { userService } from "../../_services";
import { store } from "../../_helpers";
import { userActions } from "../../_actions";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Button } from "@material-ui/core";

class AnketaFieldsPage extends React.Component {
  STATUS_NEW = "new";
  STATUS_DONE = "done";

  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      newEntitySubitems: [],
    };
  }

  prepareTypeOptions = () => {
    let options = {text: i18next.t("field_text"), checkbox: i18next.t("field_checkbox"), radio: i18next.t("field_radio")};
    return options;
  };

  getEntities = () => {
    store.dispatch(userActions.loading(true));
    userService.getWrapper(
      "api/anketafield",
      (data) => {
        store.dispatch(userActions.loading(false));
        if (data.data) {
          let entities = [];
          for (let key of Object.keys(data.data)) {
            if (data.data[key].editable) {
              data.data[key].id = key;
              entities.push(data.data[key]);
            }
          }
          this.setState({entities});
        }
      },
      (error) => {
        store.dispatch(userActions.loading(false));
        if (error !== "") {
          this.setState({ error });
          alert(error);
        }
      }
    );
  };

  getPostData = (id, fields) => {
    let postData = {rewrite_mode: 1};
    for (let field in fields) {
      postData[field] = fields[field].value;
    }
    if (id !== 0) {
      postData.id = id;
    }
    const subitems = this.getSubitems(id);
    if (subitems.length > 0) {
      postData.items = [];
      for (let subitem of subitems) {
        let postSubitem = {label: subitem.label};
        if (subitem.id) {
          postSubitem.id = subitem.id;
          postSubitem.action = "update";
        } else {
          postSubitem.action = "insert";
        }
        postData.items.push(postSubitem);
      }
    }
    return postData;
  };

  getSubitems = id => {
    let subitems = [];
    if (id === 0) {
      subitems = this.state.newEntitySubitems;
    } else {
      for (let entity of this.state.entities) {
        if (entity.id == id) {
          subitems = entity.items;
          break;
        }
      }
    }
    return subitems;
  };

  subdialog = (value, id) => {
    const isNewEntity = id === 0;
    if (value === "radio" || value === "checkbox") {
      let subitems = this.getSubitems(id);      
      const updateEntities = () => {
        if (isNewEntity) {
          this.setState({newEntitySubitems: subitems});
        } else {
          let entities = this.state.entities;
          for (let entity of this.state.entities) {
            if (entity.id == id) {
              entity.items = subitems;
              break;
            }
          }
          this.setState({entities});
        }
      };
      const addSubitem = () => {
        subitems.push({label: ""});
        updateEntities();
      };
      const removeSubitem = index => {
        subitems.splice(index, 1);
        updateEntities();
      }
      const changeSubitem = (index, value) => {
        subitems[index].label = value;
        updateEntities();
      }
      return <Box>
        {subitems.length > 0 && subitems.map((subitem, index) => <Box>
          <TextField key={subitem.id} value={subitem.label} onChange={e => changeSubitem(index, e.target.value)} />
          <Button onClick={() => removeSubitem(index)}>X</Button>
        </Box>)}
        <Button onClick={addSubitem}>{i18next.t("field_add")}</Button>
      </Box>;
    } else if (isNewEntity && this.state.newEntitySubitems.length > 0) {
      this.setState({newEntitySubitems: []});
    }
  }

  render() {
    return (
      <EntityPage
        loader={this.getEntities}
        header={{
          title: i18next.t("custom_fields"),
          description: i18next.t("custom_fields_hint"),
          actions: {
            add: { title: i18next.t("new_custom_field") },
          },
        }}
        list={{
          entities: this.state.entities,
          fields: [
            {
              name: "id",
              class: "tbl__item--text",
              head: "ID",
            },
            {
              name: "title",
              class: "tbl__item--text",
              head: i18next.t("title"),
            },
            {
              name: "type",
              class: "tbl__item--text",
              head: i18next.t("type"),
            },
            {
              name: "order",
              class: "tbl__item--text",
              head: i18next.t("order"),
            },
          ],
          actions: item => ["edit", "delete"],
        }}
        dialog={{
          title: i18next.t("new_custom_field"),
          fields: {
            title: {
              default: "",
              required: true,
              add: true,
              edit: true,
              type: "text",
              label: i18next.t("title"),
            },
            type: {
              default: null,
              required: true,
              add: true,
              edit: true,
              type: "select",
              options: this.prepareTypeOptions(),
              label: i18next.t("type"),
              subdialog: this.subdialog,
            },
            order: {
              default: null,
              required: false,
              add: true,
              edit: true,
              type: "text",
              label: i18next.t("order"),
            },
          },
          getPostData: this.getPostData,
        }}
        endpoints={{
          add: "api/anketafield",
          edit: () => "api/anketafield",
          delete: "api/anketafield",
          editMethod: userService.postWrapper,
        }}
      />
    );
  }
}

export {AnketaFieldsPage};
