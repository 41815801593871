import React from "react";
import "../css/style.css";

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { connect } from "react-redux";

import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { userActions } from "../_actions";
import { history } from "../_helpers";
import { userService } from "../_services";
import ncLogo from "../img/logo2.png";
import i18next from "i18next";

const QuestToArray = (qDict) => {
  let keys = Object.keys(qDict);

  let qArray = [];
  for (let i = 0; i < keys.length; i++) {
    qArray.push({ ...qDict[keys[i]], key: keys[i] });
  }

  return qArray;
};

const ShowCheckbox = (key, data, value, onChange) => {
  let keys = Object.keys(data);
  let tmpArray = [];
  for (let i = 0; i < keys.length; i++) {
    tmpArray.push({ value: data[keys[i]], key: keys[i] });
  }

  const handleChange = (event) => {
    if (event.target.checked) {
      onChange("checkBox", key, [...value, event.target.name]);
    } else {
      onChange(
        "checkBox",
        key,
        value.filter((e) => e != event.target.name)
      );
    }
  };

  return (
    <FormGroup>
      {tmpArray.map(function (item) {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={value.indexOf(item.key) >= 0}
                onChange={handleChange}
                color="primary"
                name={item.key}
              />
            }
            label={item.value}
          />
        );
      })}
    </FormGroup>
  );
};

const ShowRadio = (key, data, value, onChange) => {
  let keys = Object.keys(data);
  let tmpArray = [];
  for (let i = 0; i < keys.length; i++) {
    tmpArray.push({ value: data[keys[i]], key: keys[i] });
  }

  const handleChange = (event) => {
    //let val = (event.target as HTMLInputElement).value;
    onChange("radio", key, event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {tmpArray.map(function (item) {
          return (
            <FormControlLabel
              value={item.key}
              control={<Radio />}
              label={item.value}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

class VotingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      questions: [],
      answers: {},
      isSent: false,
      vkey: null,
      vid: null,
    };
  }

  componentDidMount() {
    const self = this;
    let urls = window.location.href.split("/");

    let vote_data = urls[urls.length - 1];

    let vote_id = vote_data.split("?")[0];
    let v_key = vote_data.split("=")[1];
    this.setState({ vid: vote_id, vkey: v_key });

    this.loadQuestions(vote_id, v_key);

    const language = localStorage.getItem("navic_lang") ?? "ru";

    i18next.init({
      lng: language,
      resources: require(`../_lang/${language}.json`),
    });
    
  }

  loadQuestions = (id, key) => {
    console.log(
      localStorage.getItem("token") +
        " " +
        localStorage.getItem("navic_redirect_count")
    );

    const src_url = window.location.pathname + window.location.search;

    let self = this;
    userService.getWrapper(
      "api/vote/main?signature=" + key + "&id=" + id,
      (data) => {
        console.log(data.data);

        if (data.data.header) this.setState({ title: data.data.header });

        let tmpQ = QuestToArray(data.data.questions);
        let keys = Object.keys(data.data.questions);
        let answers = {};
        for (let i = 0; i < keys.length; i++) answers[keys[i]] = [];

        self.setState({
          title: data.data.header,
          questions: tmpQ,
          answers,
          error: "",
        });
        //self.setState({quest:data.data.questions,title:data.data.header});
        localStorage.setItem("navic_redirect", "");
        localStorage.setItem("navic_redirect_count", "");
      },
      (error) => {
        console.log(
          localStorage.getItem("navic_redirect_count") +
            " " +
            localStorage.getItem("navic_user")
        );

        self.props.dispatch(userActions.set_error(error));

        if (localStorage.getItem("navic_redirect_count") == "") {
          this.setState({ error: "" });
          localStorage.setItem("navic_redirect", src_url);
          localStorage.setItem("navic_redirect_count", "1");
          history.push("/login");
        } else if (localStorage.getItem("navic_redirect_count") == "1") {
          localStorage.setItem("navic_redirect", src_url);
          localStorage.setItem("navic_user", "");
          localStorage.setItem("navic_pwd", "");
          localStorage.setItem("navic_redirect_count", "2");
          history.push("/login");
        } else {
          this.setState({
            error:
              i18next.t("incorrect_url_or_no_rules"),
          });
          localStorage.setItem("navic_redirect", "");
          setTimeout(() => {
            localStorage.setItem("navic_redirect_count", "");
          }, 2000);
        }
      }
    );
  };

  onChange = (tp, key, value) => {
    let tmpA = this.state.answers;

    if (tp == "text") {
      tmpA[key] = [value];
      this.setState({ answers: tmpA });
    }

    if (tp == "checkBox") {
      tmpA[key] = value;
      this.setState({ answers: tmpA });
    }

    if (tp == "radio") {
      tmpA[key] = [value];
      this.setState({ answers: tmpA });
    }
  };

  sendAns = () => {
    let self = this;

    let reqKeys = this.state.questions
      .filter((e) => e.required == 1)
      .map((e) => "" + e.key);

    let isOk = true;

    for (let i = 0; i < reqKeys.length; i++)
      if (this.state.answers[reqKeys[i]].length === 0) {
        isOk = false;
      }

    if (!isOk) {
      alert(i18next.t("fill_necessary_fields"));
    } else {
      userService.postWrapper(
        "api/vote/main?signature=" + this.state.vkey + "&id=" + this.state.vid,
        { answers: this.state.answers },
        function (data) {
          console.log(data);

          self.setState({ isSent: true });
        },
        function (error) {
          if (error != "") {
            self.setState({ error: error });
          }
        }
      );
    }
  };

  render() {
    const self = this;
    const { isLoading, questions, title, answers, error } = this.state;

    if (this.state.isSent) {
      return (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginLeft: 30,
              marginRight: 30,
              marginBottom: 20,
              fontSize: 30,
            }}
          >
            {i18next.t("thanks_you_answer_received")}
          </div>
        </div>
      );
    } else
      return (
        <div className="layout">
          <div style={{ marginTop: 10 }}>
            <img src={ncLogo} alt="" />
          </div>

          <Button
            variant="contained"
            onClick={() => history.push("/")}
            style={{ marginTop: 10 }}
          >
            {i18next.t("back")}
          </Button>

          <div className="layout__contains">
            <h1 className="layout__title">{title}</h1>
          </div>
          <div>
            {questions &&
              questions.map(function (item, index) {
                return (
                  <div style={{ marginBottom: 20 }} key={item.key}>
                    <h3>
                      {index + 1}. {item.description}
                      {item.required ? "*" : ""}{" "}
                    </h3>
                    <div style={{ marginTop: 10 }}>
                      {item.type === "text" && (
                        <textarea
                          style={{ maxWidth: 1000 }}
                          value={answers[item.key][0]}
                          onChange={(e) =>
                            self.onChange("text", item.key, e.target.value)
                          }
                        />
                      )}
                      {item.type === "radio" &&
                        ShowRadio(
                          item.key,
                          item.items,
                          answers[item.key],
                          self.onChange
                        )}
                      {item.type === "checkbox" &&
                        ShowCheckbox(
                          item.key,
                          item.items,
                          answers[item.key],
                          self.onChange
                        )}
                    </div>
                  </div>
                );
              })}

            {!error && (
              <Button variant="contained" onClick={self.sendAns}>
                {i18next.t("send_answer")}
                
              </Button>
            )}
            {error && <div style={{ fontSize: 30 }}>{error}</div>}
          </div>

          {isLoading && (
            <div className="shadow">
              <div className="shadow_circle">
                <CircularProgress />
              </div>
            </div>
          )}
        </div>
      );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedVotingPage = connect(mapStateToProps)(VotingPage);
export { connectedVotingPage as VotingPage };
