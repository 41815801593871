import React from "react";
import i18next from "i18next";
import { EntityPage } from "../../_components/EntityPage/EntityPage";
import { ConferenceNotSelected } from "../../_components/ConferenceNotSelected";
import { userService } from "../../_services";
import { store } from "../../_helpers";
import { userActions } from "../../_actions";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { clearDate } from "../../_helpers";
import EntityList from "../../_components/EntityPage/EntityList";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

class MailingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      showResults: false,
      results: [],
    };
  }

  prepareToOptions = () => {
    const { selectedConf } = this.props.user;
    let options = {};
    (selectedConf.ticket_types ?? []).forEach((ticketType) => {
      options[ticketType.id] = ticketType.name;
    });
    return options;
  };

  getResults = (mailingId) => {
    store.dispatch(userActions.loading(true));
    userService.getWrapper(
      "api/mailing/" + mailingId,
      (data) => {
        store.dispatch(userActions.loading(false));
        if (data.data && data.data.results) {
          console.log(data.data.results);
          this.setState({ results: data.data.results, showResults: true });
        }
      },
      (error) => {
        store.dispatch(userActions.loading(false));
        if (error !== "") {
          this.setState({ error });
          alert(error);
        }
      }
    );
  };

  getEntities = () => {
    const { selectedConf } = this.props.user;
    store.dispatch(userActions.loading(true));
    userService.getWrapper(
      "api/mailing?conference_id=" + selectedConf.id,
      (data) => {
        store.dispatch(userActions.loading(false));
        if (data.data) {
          const dataObj = { ...data.data };

          this.setState({ entities: Object.values(dataObj) });
        }
      },
      (error) => {
        store.dispatch(userActions.loading(false));
        if (error !== "") {
          this.setState({ error });
          alert(error);
        }
      }
    );
  };

  render() {
    const { selectedConf } = this.props.user;
    const id = !selectedConf || !selectedConf.id ? 0 : selectedConf.id;
    return (
      <div className="layout">
        {!id && <ConferenceNotSelected page={"mailings"} />}
        {id > 0 && (
          <EntityPage
            loader={this.getEntities}
            header={{
              title: i18next.t("mailings"),
              actions: {
                add: { title: i18next.t("new_mailing") },
              },
            }}
            list={{
              entities: this.state.entities,
              fields: [
                {
                  name: "id",
                  class: "tbl__item--text",
                  head: "ID",
                },
                {
                  name: "subject",
                  class: "tbl__item--text",
                  head: i18next.t("mail_subject"),
                },
                {
                  name: "to",
                  class: "tbl__item--text",
                  head: i18next.t("receivers_ticket_types"),
                  convertor: (data) => {
                    if (selectedConf.ticket_types && data.to) {
                      let ticketTypes = {};
                      selectedConf.ticket_types.forEach(
                        (ticketType) =>
                          (ticketTypes[ticketType.id] = ticketType.name)
                      );
                      let result = [];
                      data.to.forEach((ticketTypeId) => {
                        if (ticketTypeId in ticketTypes) {
                          result.push(
                            <li key={ticketTypeId}>
                              {ticketTypeId + ": " + ticketTypes[ticketTypeId]}
                            </li>
                          );
                        }
                      });
                      return <ul>{result}</ul>;
                    }
                  },
                },
                {
                  name: "start_from",
                  class: "tbl__item--text",
                  head: i18next.t("mailing_start_time"),
                  convertor: (data) => {
                    if (data.start_from) {
                      return clearDate(data.start_from);
                    } else {
                      return i18next.t("mailing_now");
                    }
                  },
                },
                {
                  name: "status",
                  class: "tbl__item--text",
                  head: i18next.t("status"),
                  convertor: (data) => {
                    switch (data.status) {
                      case "draft":
                        return i18next.t("mailing_draft");
                      case "wait":
                        return i18next.t("mailing_wait");
                      case "active":
                        return i18next.t("mailing_active");
                      case "done":
                        return i18next.t("mailing_done");
                    }
                  },
                },
                {
                  name: "result",
                  class: "tbl__item--text",
                  head: i18next.t("result"),
                  convertor: (data) => {
                    if (!data.result) {
                      return "";
                    }
                    let result = [];
                    "total" in data.result &&
                      result.push(
                        <li key="total">
                          {i18next.t("mailing_total") +
                            ": " +
                            data.result.total}
                        </li>
                      );
                    "success" in data.result &&
                      result.push(
                        <li key="success">
                          {i18next.t("mailing_success") +
                            ": " +
                            data.result.success}
                        </li>
                      );
                    "fail" in data.result &&
                      result.push(
                        <li key="fail">
                          {i18next.t("mailing_fail") + ": " + data.result.fail}
                        </li>
                      );
                    "finished_at" in data.result &&
                      result.push(
                        <li key="finished_at">
                          {i18next.t("mailing_finished_at") +
                            ": " +
                            clearDate(data.result.finished_at)}
                        </li>
                      );
                    return (
                      <div>
                        <ul>{result}</ul>
                        {(data.status === "active" ||
                          data.status === "done") && (
                          <Button
                            onClick={() => {
                              this.getResults(data.id);
                            }}
                          >
                            {i18next.t("details")}
                          </Button>
                        )}
                      </div>
                    );
                  },
                },
              ],
              actions: (item) =>
                item.status === "draft" ? ["run", "edit", "delete"] : [],
              customActions: {
                run: {
                  label: i18next.t("mailing_btn_run"),
                  action: (id) => {
                    store.dispatch(userActions.loading(true));
                    userService.postWrapper(
                      `api/mailing/run/${id}`,
                      {},
                      (data) => {
                        this.getEntities();
                      },
                      (error) => {
                        store.dispatch(userActions.loading(false));
                        if (error !== "") {
                          this.setState({ error });
                          alert(error);
                        }
                      }
                    );
                  },
                },
              },
            }}
            dialog={{
              title: i18next.t("new_mailing"),
              fields: {
                subject: {
                  default: "",
                  required: true,
                  add: true,
                  edit: true,
                  type: "text",
                  label: i18next.t("mail_subject"),
                },
                body: {
                  default: "",
                  required: true,
                  add: true,
                  edit: true,
                  type: "editor",
                  label: i18next.t("mailing_body"),
                },
                to: {
                  default: null,
                  required: true,
                  add: true,
                  edit: true,
                  type: "multiselect",
                  options: this.prepareToOptions(),
                  label: i18next.t("receivers_ticket_types"),
                },
                start_from: {
                  default: null,
                  required: false,
                  add: true,
                  edit: true,
                  type: "datetime",
                  label: i18next.t("mailing_start_time"),
                  sublabel: i18next.t("mailing_start_time_sublabel"),
                },
              },
            }}
            endpoints={{
              add: "api/mailing",
              edit: "api/mailing",
              delete: "api/mailing",
            }}
          />
        )}
        <Dialog open={this.state.showResults}>
          <DialogTitle>{i18next.t("mailing_result")}</DialogTitle>
          <DialogContent>
            <EntityList
              fields={[
                {
                  name: "email",
                  class: "tbl__item--text",
                  head: "email",
                },
                {
                  name: "status",
                  class: "tbl__item--text",
                  head: i18next.t("status"),
                  convertor: (data) => {
                    switch (data.status) {
                      case "wait":
                        return i18next.t("mailing_result_wait");
                      case "success":
                        return i18next.t("mailing_result_success");
                      case "fail":
                        return i18next.t("mailing_result_fail");
                    }
                  },
                },
                {
                  name: "updated_at",
                  class: "tbl__item--text",
                  head: i18next.t("mailing_result_time"),
                  convertor: (data) => clearDate(data.updated_at),
                },
              ]}
              data={this.state.results}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showResults: false })}>
              {i18next.t("btn_cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  return {
    user,
  };
}

const connectedMailingsPage = connect(mapStateToProps)(MailingsPage);
export { connectedMailingsPage as MailingsPage };
