import React from "react";
import "../css/style.css";

import { connect } from "react-redux";
import i18next from "i18next";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { userActions } from "../_actions";
import { history } from "../_helpers";
import { userService } from "../_services";

const QuestToArray = (qDict) => {
  let keys = Object.keys(qDict);

  let qArray = [];
  for (let i = 0; i < keys.length; i++) {
    qArray.push({ ...qDict[keys[i]], key: keys[i] });
  }

  return qArray;
};

class VoteResPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: "",
      questions: [],
      answers: {},
      isSent: false,
      vkey: null,
      vid: null,
    };
  }

  componentDidMount() {
    let urls = window.location.href.split("/");

    let vote_data = urls[urls.length - 1];

    let vote_id = vote_data.split("?")[0];
    let v_key = vote_data.split("=")[1];
    this.setState({ vid: vote_id, vkey: v_key });

    const language = localStorage.getItem("navic_lang") ?? "ru";

    i18next.init({
      lng: language,
      resources: require(`../_lang/${language}.json`),
    });
  }

  showAns = () => {
    const { vkey, vid } = this.state;

    this.loadResults(vid, vkey);
  };

  loadResults = (id, key) => {
    const src_url = window.location.pathname + window.location.search;

    let self = this;
    userService.getWrapper(
      "api/vote/result?signature=" + key + "&id=" + id,
      (data) => {
        if (data.data)
          this.setState({ answers: QuestToArray(data.data), error: "" });
        localStorage.setItem("navic_redirect", "");
        localStorage.setItem("navic_redirect_count", "");
      },
      (error) => {
        self.props.dispatch(userActions.set_error(error));
        if (localStorage.getItem("navic_redirect_count") == "") {
          this.setState({ error: "" });
          localStorage.setItem("navic_redirect", src_url);
          localStorage.setItem("navic_redirect_count", "1");
          history.push("/login");
        } else if (localStorage.getItem("navic_redirect_count") == "1") {
          localStorage.setItem("navic_redirect", src_url);
          localStorage.setItem("navic_user", "");
          localStorage.setItem("navic_pwd", "");
          localStorage.setItem("navic_redirect_count", "2");
          history.push("/login");
        } else {
          this.setState({
            error: i18next.t("incorrect_url_or_no_rules"),
          });
          localStorage.setItem("navic_redirect", "");
          setTimeout(() => {
            localStorage.setItem("navic_redirect_count", "");
          }, 2000);
        }

        localStorage.setItem("navic_redirect_count", "1");
      }
    );
  };

  textResult = (item) => {
    let data = Object.values(item.result);

    return (
      <div>
        <div style={{ fontSize: 18, marginBottom: 10 }}>
          {i18next.t("votes_count")}: {item.votes}
        </div>
        {data.map(function (item, index) {
          return (
            <div style={{ fontSize: 18, marginRight: 10, minWidth: 180 }}>
              {item}
            </div>
          );
        })}
      </div>
    );
  };

  diagramResult = (item) => {
    let data = Object.values(item.result);
    let maxCount = [...data].sort((a, b) => b.count - a.count)[0].count;

    return (
      <div>
        <div style={{ fontSize: 18, marginBottom: 10 }}>
          {i18next.t("votes_count")}: {item.votes}
        </div>
        {data.map(function (item, index) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: 16,
                marginBottom: 10,
              }}
            >
              <div style={{ marginRight: 10, minWidth: 180 }}>{item.label}</div>
              <div style={{ minWidth: 20, marginRight: 10 }}>{item.count}</div>
              <div
                style={{
                  backgroundColor: "blue",
                  height: 20,
                  borderRadius: 5,
                  width: maxCount > 0 ? (200 * item.count) / maxCount : 0,
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const self = this;
    const { isLoading, questions, title, answers, error } = this.state;
    const { classes, user } = this.props;

    // {this.props.conf_name}

    return (
      <div className="layout">
        <div className="layout__contains">
          <Button
            variant="contained"
            onClick={() => history.push("/")}
            style={{ marginTop: 10 }}
          >
            {i18next.t("back")}
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={self.showAns}
            style={{ marginBottom: 10 }}
          >
            {i18next.t("show_answers")}
          </Button>
          {error && <div style={{ fontSize: 30 }}>{error}</div>}
          {answers.length &&
            answers.map(function (item, index) {
              return (
                <div style={{ marginBottom: 20 }} key={item.key}>
                  <h3>
                    {index + 1}. {item.description}
                  </h3>
                  <div style={{ marginTop: 10 }}>
                    {(item.type == "radio" || item.type == "checkbox") &&
                      self.diagramResult(item)}
                    {item.type == "text" && self.textResult(item)}
                  </div>
                </div>
              );
            })}
        </div>

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedVoteResPage = connect(mapStateToProps)(VoteResPage);
export { connectedVoteResPage as VoteResPage };
