import React, {
  createContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { Snackbar, Alert } from "@mui/material";

// Создаем контекст
export const SnackbarContext = createContext();

// Провайдер для снекбара
export const SnackbarProvider = ({ children }) => {
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info"); // Тип сообщения: 'success', 'error', 'warning', 'info'
  const [open, setOpen] = useState(false);
  const resolveRef = useRef(null);

  // Функция для показа снекбара
  const showSnackbar = useCallback((text, type = "info") => {
    return new Promise((resolve) => {
      setMessage(text);
      setSeverity(type);
      setOpen(true);
      resolveRef.current = resolve; // Сохраняем resolve для закрытия
    });
  }, []);

  useEffect(() => {
    initSnackbarService(showSnackbar);
  }, [showSnackbar]);

  // Функция для закрытия снекбара
  const closeSnackbar = useCallback(() => {
    setOpen(false);
    if (resolveRef.current) {
      resolveRef.current(); // Вызываем resolve при закрытии
      resolveRef.current = null;
    }
  }, []);

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      {/* Компонент Snackbar */}
      <Snackbar
        open={open}
        autoHideDuration={5000} // Автоматическое закрытие через 3 секунды
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

// Экспорт функции для вызова снекбара извне
let globalShowSnackbar;

export const initSnackbarService = (showSnackbar) => {
  globalShowSnackbar = showSnackbar;
};

export const showGlobalSnackbar = (text, type = "info") => {
  if (!globalShowSnackbar) {
    console.error("Snackbar service is not initialized");
    return;
  }
  globalShowSnackbar(text, type);
};
