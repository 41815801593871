import { CircularProgress, TextField } from "@material-ui/core";
import React from "react";
import { createRef, Component } from "react";
import { connect } from "react-redux";
import { appActions, userActions } from "../_actions";
import { EditButton } from "../_components/buttons/EditButton";
import { store } from "../_helpers";
import { confService, userService } from "../_services";
import "../css/style.css";
import confLogo from "../img/conf_logo.png";
import ConferenceAnketa from "./ConferenceAnketa/ConferenceAnketa";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import MenuItem from "@mui/material/MenuItem";
import { ConferenceNotSelected } from "../_components/ConferenceNotSelected";
import SponsorDialog from "./SponsorDialog";

import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import Select from "@mui/material/Select";
import i18next from "i18next";
import InputI18N from "../_components/InputI18N";
import LabelHint from "../_components/LabelHint";
import TextAreaI18N from "../_components/TextAreaI18N";
import { serverUrl } from "../_constants";
import BldDialog from "./BldDialog";
import DropFile from "../_components/DropFile";
import TabEditor from "../_components/TabEditor";
import TranslationSettings from "../_components/Translation/TranslationSettings";

class CreateConfPage extends React.Component {
  constructor(props) {
    super(props);
    this.afterAddActions = {};

    this.state = {
      value: "one",
      isOpen: false,
      loadingTickets: false,
      streamUrl: "",
      sponsors: [],
      sp_list: [],
      tickets: [],
      tickets_tmp: [],
      name: "",
      name_i18n: {},
      id: 0,
      theme: "",
      date: "3",
      adress: "4",
      part_count: "0",
      spons_count: "0",
      start_date: null,
      end_date: null,
      blds: [],
      selectedBldId: 0,
      bldAddr: "",
      bldDialog: false,
      bldId: 0,
      spId: 0,
      spDialog: false,
      dName: "",
      dNameI18N: {},
      dDesc: "",
      dDescI18N: {},
      hidden_without_tickets: 0,
      questionable: 1,
      dAddress: "",
      dEmail: "",
      format: "hybrid",
      contacts: "",
      contacts_i18n: {},
      wifi: "",
      wifi_i18n: {},
      faq: "",
      description: "",
      description_i18n: {},
      urlt: "",
      is_published: 0,
      brief: "",
      brief_id: 0,
      langList: [],
      allLang: {
        ru: i18next.t("lang_ru"),
        en: i18next.t("lang_en"),
        cn: i18next.t("lang_cn"),
      },
      confLimits: null,
      premiumState: "",
      isMultilang: false,
      requiredAnketa: [],
      optionalAnketa: [],
      allAnketaFields: [],
      advancedSettingsIsOpen: false,
      createTranslationForNewConference: 0,
      translation: null,
    };

    this.editorRef = createRef();
  }

  clickLangItem = (item) => {
    let { langList, name } = this.state;

    const oldLangLength = langList.length;

    if (langList.indexOf(item) >= 0) {
      //if (item != "ru")
      langList = langList.filter((e) => e != item);
    } else {
      langList.push(item);
    }

    langList = langList.sort((a, b) => {
      if (a == "ru" && b == "en") return -1;
      if (a == "ru" && b == "cn") return -1;
      if (a == "en" && b == "cn") return -1;

      return 1;
    });

    console.log(langList);

    if (oldLangLength == 0 && langList.length > 0 && name != "") {
      const name_i18n = { ru: name, en: name, cn: name };
      this.setState({ langList, isMultilang: langList.length > 0, name_i18n });
    } else this.setState({ langList, isMultilang: langList.length > 0 });
  };

  getAllAnketaFields = () => {
    userService.getWrapper(
      "api/anketafield",
      (data) => {
        this.setState({ allAnketaFields: data.data });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  storeChange = () => {
    const { dispatch } = this.props;

    if (store.getState().user.isLoading === false) {
      if (store.getState().user.error === "") {
        dispatch(appActions.setpage("conf_list"));
      }
    }
  };

  componentDidMount() {
    const { selectedConf } = this.props.user;

    this.setState({
      lang: this.props.app.lang,
    });

    if (!(selectedConf && selectedConf.id)) {
      this.props.dispatch(appActions.setpage("new_conf"));
    }

    if (selectedConf && selectedConf.id) {
      this.getTickets(selectedConf.id);
      this.getBuildings(selectedConf.building_id);
      this.getConfInfo(selectedConf.id);
      this.getLimitInfo(selectedConf.id);
    } else this.getBuildings();

    this.getSponsors(0);
    this.getAllAnketaFields();
  }

  componentWillReceiveProps(nextProps) {
    const { selectedConf } = this.props.user;

    //this.setState({ lang: this.props.app.lang });
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });

      if (selectedConf && selectedConf.id) {
        this.getBuildings(selectedConf.building_id);
      } else this.getBuildings();
      this.getSponsors(0);
    }
  }

  getLimitInfo = (id) => {
    userService.getWrapper(
      "api/tariff/restriction/conference/" + id,
      (data) => {
        const keys = Object.keys(data.data);
        const result =
          keys.length > 0
            ? keys.map(function (key) {
                const max = data.data[key]["max"] ?? i18next.t("unlimited");
                return (
                  <div>
                    {key}: {data.data[key]["current"] ?? 0} / {max}
                  </div>
                );
              })
            : i18next.t("unlimited");
        this.setState({ confLimits: <div>{result}</div> });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  setConfToState(confData) {
    let langList = confData.languages;

    if (!langList) langList = ["ru"];

    langList = langList.sort((a, b) => {
      if (a == "ru" && b == "en") return -1;
      if (a == "ru" && b == "cn") return -1;
      if (a == "en" && b == "cn") return -1;

      return 1;
    });

    let tmpObj = {};
    let field_list = ["name", "faq", "contacts", "description", "wifi"];

    for (let item of field_list) {
      tmpObj[item + "_i18n"] = confData[item + "_i18n"];
    }

    for (let key of Object.keys(tmpObj)) {
      console.log(key, tmpObj[key]);

      if (!tmpObj[key]) {
        let new_key = key.replace("_i18n", "");

        tmpObj[key] = {};

        for (let item in this.state.allLang) {
          if (langList.includes(item)) {
            tmpObj[key][item] = confData[new_key];
          } else {
            tmpObj[key][item] = "";
          }
        }
      }
    }

    let oldObj = {
      id: confData.id,
      name: confData.name,
      format: confData.type,
      description: confData.description,
      streamUrl: confData.navigation_description,
      id: confData.id,
      faq: confData.faq,
      wifi: confData.wifi,
      contacts: confData.contacts,
      is_published: confData.is_published == "draft" ? 0 : 1,
      image: confData.image,
      start_date: confData.start_time
        ? confData.start_time.substr(0, 10)
        : null,
      end_date: confData.end_time ? confData.end_time.substr(0, 10) : null,
      start_time: confData.start_time
        ? confData.start_time.substr(11, 5)
        : null,
      end_time: confData.end_time ? confData.end_time.substr(11, 5) : null,
      selectedBldId: confData.building_id,
      part_count: confData.participants_count,
      sp_list: confData.sponsors,
      spons_count: confData.sponsors_count,
      langList: langList,
      hidden_without_tickets: confData.hidden_without_tickets,
      questionable: confData.questionable,
      premiumState: confData.canBePremium,
      isMultilang: langList.length > 0,
      requiredAnketa: confData.required_anketa,
      optionalAnketa: confData.optional_anketa,
      translation: confData.translation,
      urlt: confData.url,
      domain: confData.domain,
      metrics: confData.metrics,
    };

    for (let field of field_list)
      oldObj[field + "_i18n"] = tmpObj[field + "_i18n"];

    this.setState(oldObj);

    let brief_docs = confData.documents.filter(
      (e) => e.type == "presentation_template"
    );

    if (brief_docs.length > 0) {
      this.setState({
        brief_id: brief_docs[0].id,
        brief: brief_docs[0].description,
      });
    }
  }

  getBrief = () => {};

  saveScheduleDoc = (conf_id) => {};

  saveHintDoc = (conf_id) => {
    let { id, brief, brief_id } = this.state;
    if (conf_id) id = +conf_id;
  };

  saveBrief = (conf_id = undefined) => {
    const { dispatch } = this.props;

    let { id, brief, brief_id } = this.state;

    if (conf_id) id = +conf_id;

    if (id >= 0 && brief) {
      let post_data = {
        documentable_id: id,
        documentable_type: "conference",
        type: "presentation_template",
        description: brief,
        access: "team",
        link: "https://naviconf.com/",
      };

      if (brief_id > 0) {
        post_data.id = brief_id;
      }

      userService.postWrapper(
        "api/document",
        post_data,
        () => {},
        (error) => {
          if (error !== "") {
            dispatch(userActions.set_error(i18next.t("cant_execute_request")));
          }
        }
      );
    }

    if (id >= 0 && brief == "" && brief_id) {
      userService.delWrapper(
        "api/document/" + brief_id,
        (data) => {
          console.log(data);
        },
        (error) => {
          if (error !== "") {
            dispatch(userActions.set_error(i18next.t("cant_execute_request")));
          }
        }
      );
    }
  };

  getConfInfo = (id, is_save = false) => {
    const self = this;
    const { dispatch } = this.props;

    confService.confInfo(
      id,
      (data) => {
        if (data.status === 200) {
          self.setConfToState(data.data);

          dispatch(userActions.set_conf({ ...data.data }));
        }
      },
      (error) => {
        dispatch(userActions.set_error(i18next.t("cant_execute_request")));
      }
    );
  };

  editSponsors = () => {
    const { id } = this.state;

    this.setState({ spId: id, spDialog: true });
  };

  resetConf = () => {
    this.setState({
      name: "",
      start_time: null,
      end_time: null,
      selectedBldId: null,
      wifi: "",
      description: "",
      faq: "",
      contacts: "",
    });
  };

  setPremium = () => {
    const { id } = this.state;
    const { classes, dispatch } = this.props;
    let self = this;

    userService.postWrapper(
      "api/conference/" + id + "/premium",
      {},
      () => {
        self.getConfInfo(id);
        self.getLimitInfo(id);
      },
      (error) => {
        if (error !== "") {
          dispatch(userActions.set_error(i18next.t("cant_execute_request")));
        }
      }
    );
  };

  showTextForBuy = () => {
    const { classes, dispatch } = this.props;

    dispatch(
      userActions.set_error(i18next.t("for_ticket_paid_write_to_nc"), "info")
    );
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      this.uploadFile(acceptedFiles[0]);
    }
  };

  uploadFile(selectedFile) {
    const self = this;
    const { dispatch } = this.props;
    const data_upload = new FormData();
    data_upload.append("image", selectedFile);
    const uploader = () =>
      userService.uploadFile(
        `api/conference/${this.state.id}/upload`,
        data_upload,
        (data) => {
          if (data && data.data && data.data.image)
            self.setState({ image: data.data.image });
          else
            dispatch(userActions.set_error(i18next.t("cant_execute_request")));
        },
        (error) => {
          dispatch(userActions.set_error(i18next.t("cant_execute_request")));
        }
      );
    if (this.state.id > 0) {
      uploader();
    } else {
      this.afterAddActions["uploader"] = uploader;
      self.setState({ image: URL.createObjectURL(selectedFile) });
    }
  }

  saveTicket = (index) => {
    const { id, tickets_tmp, isMultilang } = this.state;
    const { dispatch } = this.props;

    const tmp_t = { ...tickets_tmp[index] };

    console.log("T", index, tickets_tmp[index]);

    if (
      (tmp_t.name != "" && !isMultilang) ||
      (isMultilang &&
        (tmp_t.name_i18n["ru"] ||
          tmp_t.name_i18n["en"] ||
          tmp_t.name_i18n["cn"]))
    ) {
      const ticketId = tmp_t.id;

      // let post_data = {conference_id: id,...tmp_t};
      const post_data = {
        conference_id: id,
        //name: tmp_t.name,
        //name_i18n: tmp_t.name_i18n,
      };

      if (isMultilang) {
        post_data.name_i18n = tmp_t.name_i18n;
        post_data.name =
          tmp_t.name_i18n["ru"] ??
          tmp_t.name_i18n["en"] ??
          tmp_t.name_i18n["cn"];
      } else {
        post_data.name = tmp_t.name;
      }

      //if (!post_data.name) post_data.name = post_data.name_i18n["ru"];

      if (tmp_t.cost > 0) post_data.cost = tmp_t.cost;
      else post_data.cost = "";

      let self = this;
      this.setState({ loadingTickets: true });

      if (ticketId > 0) {
        userService.patchWrapperBody(
          `api/ticket-type/${ticketId}`,
          post_data,
          () => {
            self.getTickets(self.state.id);
          },
          (error) => {
            if (error != "") {
              self.setState({ error, loadingTickets: false });
              dispatch(
                userActions.set_error(i18next.t("cant_execute_request"))
              );
            }
          }
        );
      } else {
        userService.postWrapper(
          "api/ticket-type",
          post_data,
          (data) => {
            self.getTickets(self.state.id);
          },
          (error) => {
            if (error != "") {
              dispatch(
                userActions.set_error(i18next.t("cant_execute_request"))
              );
            }
          }
        );
      }
    }
  };

  delTicket = (index) => {
    const { tickets_tmp } = this.state;
    const { dispatch } = this.props;
    const tmp_t = { ...tickets_tmp[index] };
    const ticketId = tmp_t.id;
    let self = this;

    if (ticketId > 0) {
      this.setState({ loadingTickets: true });

      userService.delWrapper(
        `api/ticket-type/${ticketId}`,
        (data) => {
          self.setState({ loadingTickets: false });
          self.getTickets(self.state.id);
        },
        (error) => {
          if (error !== "") {
            self.setState({ loadingTickets: false });
            dispatch(userActions.set_error(error));
          }
        }
      );
    } else {
      tickets_tmp.splice(index, 1);
      this.setState({ tickets_tmp: [...tickets_tmp] });
    }
  };

  editTicket = (indexT, val, type) => {
    if (val != null) {
      const { tickets_tmp } = this.state;

      if (indexT >= 0) {
        const tmp_obj = { ...tickets_tmp[indexT] };
        if (type == "name") tmp_obj.name = val;
        if (type == "cost") tmp_obj.cost = val;

        tickets_tmp[indexT] = { ...tmp_obj };
        this.setState({ tickets_tmp });
      }
    }
  };

  editTicketPrice = (indexT, val) => {
    if (val != null) {
      const { tickets_tmp } = this.state;

      if (indexT >= 0) {
        const tmp_obj = { ...tickets_tmp[indexT] };
        tmp_obj.cost = val;

        tickets_tmp[indexT] = { ...tmp_obj };
        this.setState({ tickets_tmp });
      }
    }
  };

  editTicketI18N = (indexT, val, tabName) => {
    if (val != null) {
      const { tickets_tmp, isMultilang } = this.state;

      console.log(indexT, val, tabName, isMultilang);

      if (indexT >= 0) {
        const tmp_obj = { ...tickets_tmp[indexT] };

        let obj_name = tmp_obj.name_i18n;
        obj_name[tabName] = val;

        if (isMultilang) tmp_obj.name_i18n = { ...obj_name };
        else tmp_obj.name = val;

        tickets_tmp[indexT] = { ...tmp_obj };
        this.setState({ tickets_tmp });
      }
    }
  };

  addTicket = () => {
    const { tickets_tmp } = this.state;

    tickets_tmp.push({ id: 0, name: "", cost: "", name_i18n: {} });
    this.setState({ tickets_tmp });
  };

  getTickets = (id) => {
    let self = this;
    const { dispatch } = this.props;

    if (id > 0) {
      this.setState({ loadingTickets: true });

      userService.getWrapper(
        `api/ticket-type?conference_id=${id}`,
        (data) => {
          if (data.data) {
            let tmp_data = [...data.data];
            tmp_data = tmp_data.map((item) => ({
              ...item,
              name: item.name,
              name_i18n: item.name_i18n ?? { ru: item.name, en: "", cn: "" },
              cost: item.cost ? item.cost : 0,
            }));
            self.setState({ tickets_tmp: tmp_data, loadingTickets: false });
          } else self.setState({ tickets_tmp: [], loadingTickets: false });
        },
        (error) => {
          if (error != "") {
            dispatch(userActions.set_error(error));
          }
        }
      );
    }
  };

  getSponsors = (id) => {
    let self = this;
    const { classes, dispatch } = this.props;

    let getUrl = "api/sponsor";
    if (id > 0) getUrl += `?conference_id=${id}`;

    userService.getWrapper(
      getUrl,
      (data) => {
        if (id > 0) {
          if (data.data)
            self.setState({
              sponsors: [...data.data],
              spons_count: `${data.data.length}`,
            });
          else self.setState({ sponsors: [] });
        } else if (data.data) self.setState({ sp_list: [...data.data] });
        else self.setState({ sp_list: [] });
      },
      (error) => {
        if (error != "") {
          self.setState({ error });
          dispatch(userActions.set_error(error, "error"));
        }
      }
    );
  };

  setBld = (id) => {
    let addr = "";
    const { blds } = this.state;

    for (let i = 0; i < blds.length; i++)
      if (blds[i].id == id) addr = blds[i].address;

    this.setState({ selectedBldId: id, bldAddr: addr });
  };

  getBuildings = (id = 0) => {
    let self = this;

    userService.getWrapper(
      "api/building",
      (data) => {
        if (data.data) {
          if (id > 0) {
            let addr = "";
            for (let i = 0; i < data.data.length; i++) {
              if (data.data[i].id == id) {
                addr = data.data[i].address;
                break;
              }
            }
            self.setState({
              blds: [...data.data],
              bldAddr: addr,
              selectedBldId: id,
            });
          } else self.setState({ blds: [...data.data] });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
      }
    );
  };

  setConfData = () => {
    const { dispatch } = this.props;

    let {
      name,
      start_date,
      end_date,
      start_time,
      end_time,
      selectedBldId,
      faq,
      wifi,
      contacts,
      format,
      id,
      langList,
      name_i18n,
      contacts_i18n,
      faq_i18n,
      wifi_i18n,
      description_i18n,
      description,
      hidden_without_tickets,
      questionable,
      requiredAnketa,
      optionalAnketa,
      domain,
      metrics,
    } = this.state;

    let selectedBldOk = true;

    if (format !== "online" && !selectedBldId) selectedBldOk = false;

    if (
      (!name && !name_i18n["ru"]) ||
      !(start_date && end_date && selectedBldOk)
    ) {
      let tmp_str = i18next.t("fill_fields_name_dates");

      if (format !== "online" && !selectedBldId)
        tmp_str += ", " + i18next.t("select_bld");

      dispatch(userActions.set_error(tmp_str, "warning"));

      return;
    }

    if (!name) {
      name = name_i18n["ru"];
    }

    let tmp_start_time = "09:00";
    let tmp_end_time = "18:00";

    if (start_time) tmp_start_time = start_time;

    if (end_time) tmp_end_time = end_time;

    const endDate = new Date(end_date + "T" + tmp_end_time + ":00.000Z");
    let curDate = new Date();
    curDate.setDate(curDate.getDate() - 1);
    const startDate = new Date(start_date + "T" + tmp_start_time + ":00.000Z");

    if (endDate < startDate) {
      dispatch(
        userActions.set_error(i18next.t("start_end_date_text"), "warning")
      );

      return;
    }

    if ((id == 0 && curDate > startDate) || (id > 0 && curDate > endDate)) {
      dispatch(
        userActions.set_error(i18next.t("start_date_in_past_text"), "warning")
      );
      return;
    }
    const post_obj = {
      name: name,
      name_i18n: name_i18n,
      start_time: start_date + "  " + tmp_start_time + ":00",
      end_time: end_date + "  " + tmp_end_time + ":00",
      description: description,
      description_i18n,
      faq: faq,
      faq_i18n,
      wifi: wifi,
      wifi_i18n,
      contacts: contacts,
      contacts_i18n,
      type: format,
      languages: langList,
      hidden_without_tickets,
      questionable,
      required_anketa: requiredAnketa,
      optional_anketa: optionalAnketa,
      create_translation: this.state.createTranslationForNewConference,
      url: this.state.urlt,
      domain,
      metrics,
    };

    if (selectedBldId) post_obj.building_id = selectedBldId;

    return { ...post_obj };
  };

  saveConf = (is_publish = 0) => {
    let self = this;
    const { dispatch } = this.props;
    const { tickets_tmp, start_date, start_time } = this.state;

    let post_obj = this.setConfData();

    if (!post_obj) {
      return;
    }

    if (is_publish == 1 && tickets_tmp.length > 0) {
      let isFail = false;
      for (let i = 0; i < tickets_tmp.length; i++)
        if (tickets_tmp[i].name === "") isFail = true;

      if (isFail) {
        dispatch(
          userActions.set_error(
            i18next.t("tickets_name_cant_be_empty"),
            "warning"
          )
        );
        return;
      }
    }

    if (is_publish >= 0) post_obj.is_published = is_publish;
    else post_obj.is_published = 0;

    console.log(post_obj);

    userService.patchWrapperBody(
      `api/conference/${this.state.id}`,
      post_obj,
      (data) => {
        console.log("SAVE", post_obj);
        const tmp_str = i18next.t("changes_saved");
        dispatch(userActions.set_error(tmp_str, "success"));

        self.getConfInfo(self.state.id, true);
      },
      (error) => {
        dispatch(userActions.set_error(error, "error"));
      }
    );

    this.saveBrief();
  };

  addConf = (is_publish = 0) => {
    const self = this;
    const { classes, dispatch } = this.props;
    const { selectedBldId } = this.state;

    let post_obj = this.setConfData();

    if (!post_obj) return;

    if (selectedBldId) post_obj.building_id = selectedBldId;

    userService.postWrapper(
      "api/conference",
      post_obj,
      (data) => {
        if (data.data && data.data > 0) {
          self.setState({ id: data.data, is_published: is_publish });
          self.getConfInfo(self.state.id, true);
          self.saveBrief(data.data);
          self.getLimitInfo(self.state.id);
          for (let action of Object.keys(this.afterAddActions)) {
            this.afterAddActions[action]();
          }
        } else {
          const tmp_str = i18next.t("changes_saved");
          dispatch(userActions.set_error(tmp_str, "success"));
          self.getConfInfo(self.state.id, true);
        }
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  };

  onPublic = (n) => {
    const { id } = this.state;
    const { classes, dispatch } = this.props;

    if (id > 0) this.saveConf(n);
    else
      dispatch(
        userActions.set_error(i18next.t("need_save_before_public"), "warning")
      );
    //this.addConf(n);
  };

  onAdd = () => {
    this.addConf();
  };

  onSave = () => {
    this.saveConf(-1);
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  descChange = (val, tabName) => {
    const { isMultilang } = this.state;

    let tmpObj = this.state["description_i18n"] ?? {};
    tmpObj = { ...tmpObj };
    tmpObj[tabName] = val;

    if (isMultilang) {
      this.setState({ description_i18n: tmpObj });
    } else {
      this.setState({ description: val });
    }
  };

  handleChangeI18N = (event, tabName) => {
    const { isMultilang } = this.state;

    let tmpObj = this.state[event.target.name + "_i18n"] ?? {};
    tmpObj = { ...tmpObj };
    tmpObj[tabName] = event.target.value;

    if (isMultilang) {
      this.setState({ [event.target.name + "_i18n"]: tmpObj });
    } else {
      console.log(event.target.value, event.target.name);
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  changeDate = (index, event) => {
    if (index == 0) this.setState({ start_date: event.target.value });
    else this.setState({ end_date: event.target.value });
  };

  changeTime = (index, event) => {
    if (index == 0) this.setState({ start_time: event.target.value });
    else this.setState({ end_time: event.target.value });
  };

  handleChangeAdress = (index, col, event) => {
    /*
        let { blds } = this.state;
        let tmp_item = adresses[index];
        tmp_item[col] = event.target.value;
        adresses[index] = {...tmp_item}
        this.setState({adresses:adresses});
        */
  };

  deleteAddr = (id) => {
    const self = this;
    const { selectedBldId } = this.state;
    const { dispatch } = this.props;

    if (selectedBldId > 0) {
      userService.delWrapper(
        `api/building/${selectedBldId}`,
        function (data) {
          if (data.status == 200) self.getBuildings();
        },
        function (error) {
          if (error != "") {
            self.setState({ error: error, isLoading: false });
            dispatch(userActions.set_error(error, "error"));
          }
        }
      );
    }
  };

  editAddr = (id) => {
    const { blds } = this.state;
    let bldAddr = "";
    let bldName = "";
    let bldEmail = "";
    let bldDesc = "";
    let bldNameI18N = {};
    let bldDescI18N = {};
    for (let i = 0; i < blds.length; i++) {
      if (blds[i].id == id) {
        bldAddr = blds[i].address;
        bldName = blds[i].name;
        bldEmail = blds[i].email;
        bldDesc = blds[i].description;
        bldNameI18N = blds[i].name_i18n;
        bldDescI18N = blds[i].description_i18n;
      }
    }

    this.setState({
      bldId: id,
      dAddress: bldAddr,
      dName: bldName,
      dEmail: bldEmail,
      dDesc: bldDesc,
      dNameI18N: bldNameI18N,
      dDescI18N: bldDescI18N,
      bldDialog: true,
    });
  };

  addAddr = () => {
    this.setState({
      bldId: 0,
      dAddress: "",
      dName: "",
      dNameI18N: {},
      dDescI18N: {},
      dDesc: "",
      dEmail: "",
      bldDialog: true,
    });
  };

  closeSpDialog = () => {
    this.setState({ spId: 0, spDialog: false });
  };

  closeBldDialog = (val) => {
    console.log(val);

    const self = this;
    const { bldId } = this.state;
    const { dispatch } = this.props;

    const post_data = {};

    if (val.address) post_data.address = val.address;
    if (val.name) post_data.name = val.name;
    if (val.email || val.email == "") post_data.email = val.email;
    if (val.name_i18n) post_data.name_i18n = val.name_i18n;
    if (val.description_i18n) post_data.description_i18n = val.descriptiom_i18n;
    if (val.description || val.description == "")
      post_data.description = val.description;

    if (bldId == 0) {
      if (val.comment || val.comment == "") post_data.comment = val.comment;
      if (val.person || val.person == "") post_data.persons = val.person;
      if (val.phone || val.phone == "") post_data.phone = val.phone;
    }

    if (post_data && post_data.name) {
      if (bldId > 0) {
        userService.patchWrapperBody(
          `api/building/${bldId}`,
          post_data,
          (data) => {
            if (data.status == 200) self.getBuildings();
          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              dispatch(userActions.set_error(error, "error"));
            }
          }
        );
      } else {
        userService.postWrapper(
          "api/building",
          post_data,
          (data) => {
            if (data.status === 200) self.getBuildings();
          },
          (error) => {
            if (error !== "") {
              self.setState({ error, isLoading: false });
              dispatch(userActions.set_error(error, "error"));
            }
          }
        );
      }

      this.setState({ bldDialog: false });
    } else {
      this.setState({ bldDialog: false });
    }
  };

  createTranslation = (type, id, afterCreate) => {
    let self = this;

    userService.postWrapper(
      "api/translation?entity_type=" + type + "&entity_id=" + id,
      {},
      (data) => afterCreate(data.data),
      (error) => {
        if (error != "") {
          self.setState({ error });
        }
      }
    );
  };

  delTranslation = (type, id) => {
    let self = this;

    userService.delWrapper(
      "api/translation?entity_type=" + type + "&entity_id=" + id,
      () => {},
      (error) => {
        if (error != "") {
          self.setState({ error });
        }
      }
    );
  };

  stateSetter = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const self = this;
    const {
      id,
      bldAddr,
      sponsors,
      sp_list,
      spId,
      spDialog,
      image,
      streamUrl,
      loadingTickets,
      tickets_tmp,
      bldDialog,
      bldId,
      dName,
      dNameI18N,
      dDesc,
      dDescI18N,
      dEmail,
      dAddress,
      selectedBldId,
      start_time,
      end_time,
      start_date,
      end_date,
      blds,
      format,
      part_count,
      spons_count,
      name,
      is_published,
      urlt,
      brief,
      langList,
      allLang,
      name_i18n,
      contacts,
      description,
      contacts_i18n,
      description_i18n,
      faq,
      wifi,
      faq_i18n,
      wifi_i18n,
      questionable,
      hidden_without_tickets,
      isMultilang,
      premiumState,
      translation,
      domain,
      metrics,
    } = this.state;

    const { selectedConf, isConfSelected } = this.props.user;

    let conf_url =
      window.location.protocol + "//" + window.location.host + "/event/" + id;

    console.log("DESC", description);

    return (
      <div className="layout__wrap">
        {isConfSelected && !selectedConf && (
          <ConferenceNotSelected page={"conf_param"} />
        )}
        {(!isConfSelected || selectedConf) && (
          <div className="layout__cols">
            <div
              className="layout__block"
              style={{ flex: 1, marginLeft: 30, marginRight: 30 }}
            >
              <div className="form">
                <div className="form__col">
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <div className="form__group" style={{ width: "48%" }}>
                      <LabelHint title="event_name" hint="hint_event_name" />

                      <InputI18N
                        id="text1"
                        type="text"
                        className="form__input"
                        name="name"
                        value={isMultilang ? name_i18n ?? {} : name}
                        onChange={this.handleChangeI18N}
                        placeholder={i18next.t("event_name")}
                        langList={langList}
                        isMultilang={isMultilang}
                      />
                    </div>

                    <div className="form__group" style={{ width: "48%" }}>
                      <LabelHint title="event_dates" hint="hint_event_dates" />

                      <div className="form__group-body">
                        <div className="form__group-rows">
                          <div className="date-wrap form__group-row">
                            <label htmlFor="start">
                              {i18next.t("date_start")}
                            </label>
                            <span>{"   "}</span>
                            <input
                              required
                              type="date"
                              className="form__input"
                              id="start"
                              value={start_date}
                              name="trip-start"
                              onChange={(e) => this.changeDate(0, e)}
                            />
                          </div>

                          <div className="date-wrap form__group-row">
                            <label htmlFor="date">
                              {i18next.t("time_start")} {"   "}
                            </label>
                            <span>{"   "}</span>
                            <input
                              type="time"
                              value={start_time}
                              class="form__input"
                              id="start"
                              name="trip-start"
                              onChange={(e) => this.changeTime(0, e)}
                            />
                          </div>
                        </div>
                        <div className="form__group-rows">
                          <div className="date-wrap form__group-row">
                            <label htmlFor="start">
                              {i18next.t("date_end")}
                            </label>
                            <input
                              required
                              type="date"
                              className="form__input"
                              id="start"
                              value={end_date}
                              name="trip-start"
                              onChange={(e) => this.changeDate(1, e)}
                            />
                          </div>

                          <div className="date-wrap form__group-row">
                            <label htmlFor="date">
                              {i18next.t("time_end")}
                            </label>
                            <input
                              type="time"
                              value={end_time}
                              class="form__input"
                              id="start"
                              name="trip-start"
                              onChange={(e) => this.changeTime(1, e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", flex: 1, flexDirection: "row" }}
                  >
                    <div className="form__group" style={{ width: "48%" }}>
                      <LabelHint
                        title="event_format"
                        hint="hint_event_format"
                      />

                      <div className="radio-buttons">
                        <div
                          className="radio-button"
                          onClick={() => self.setState({ format: "online" })}
                        >
                          <span
                            id="r1"
                            name="category"
                            class={
                              format === "online"
                                ? "radio-button__checked"
                                : "radio-button__part"
                            }
                          >
                            {i18next.t("online_text")}
                          </span>
                        </div>
                        <div
                          className="radio-button"
                          onClick={() => self.setState({ format: "hybrid" })}
                        >
                          <span
                            id="r2"
                            name="category"
                            class={
                              format === "hybrid"
                                ? "radio-button__checked"
                                : "radio-button__part"
                            }
                          >
                            {i18next.t("hybrid_text")}
                          </span>
                        </div>
                        <div
                          className="radio-button"
                          onClick={() => self.setState({ format: "offline" })}
                        >
                          <span
                            id="r3"
                            name="category"
                            class={
                              format === "offline"
                                ? "radio-button__checked"
                                : "radio-button__part"
                            }
                          >
                            {i18next.t("offline_text")}
                          </span>
                        </div>
                      </div>
                    </div>

                    {format !== "offline" && (
                      <div style={{ width: "48%", marginLeft: 20 }}>
                        <TranslationSettings
                          translation={translation}
                          translationUrl={urlt}
                          setTranslationUrl={(url) =>
                            this.setState({ urlt: url })
                          }
                          createTranslation={(afterCreate) =>
                            self.createTranslation(
                              "conference",
                              id,
                              afterCreate
                            )
                          }
                          delTranslation={() =>
                            self.delTranslation("conference", id)
                          }
                          delayedCreation={
                            id == 0 &&
                            ((value) =>
                              this.setState({
                                createTranslationForNewConference: value,
                              }))
                          }
                          hint="translation_settings_hint"
                        />
                      </div>
                    )}
                  </div>

                  {format !== "online" && (
                    <div class="form__group">
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <LabelHint
                          title="event_addres"
                          hint="hint_event_addres"
                        />
                        <div class="form__group-top">
                          <button
                            type="button"
                            class="form__btn"
                            onClick={this.addAddr}
                          >
                            <svg width="29" height="29">
                              <use href="./img/sprite.svg#add-icon"></use>
                            </svg>
                            <span>{i18next.t("btn_add")}</span>
                          </button>
                        </div>
                      </div>

                      <div class="form__group-body">
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                          }}
                        >
                          <Select
                            style={{ width: "100%" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedBldId}
                            label="Blds"
                            onChange={(e) => this.setBld(e.target.value)}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                              },
                            }}
                          >
                            {blds.length > 0 &&
                              blds.map((item, index) => (
                                <MenuItem
                                  value={item.id}
                                  style={{
                                    maxWidth: "20vw",
                                  }}
                                >
                                  {item.address}{" "}
                                  {item.name ? "(" + item.name + ")" : ""}{" "}
                                </MenuItem>
                              ))}
                          </Select>
                          <div
                            style={{
                              width: 20,
                              paddingLeft: 10,
                              marginTop: 10,
                            }}
                          >
                            <DeleteIcon
                              onClick={() =>
                                self.setState({ selectedBldId: null })
                              }
                              width="15"
                              height="15"
                            />
                          </div>
                        </div>
                        {selectedBldId > 0 && (
                          <button
                            type="button"
                            class="form__btn"
                            onClick={(e) => this.editAddr(selectedBldId)}
                          >
                            <span>{i18next.t("btn_edit")}</span>
                            <svg width="29" height="29" fill="none"></svg>
                          </button>
                        )}
                        {selectedBldId > 0 && (
                          <button
                            type="button"
                            class="form__btn"
                            onClick={(e) => this.deleteAddr(selectedBldId)}
                          >
                            <span>{i18next.t("btn_delete")}</span>
                            <svg width="29" height="29" fill="none"></svg>
                          </button>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="form__group">
                    {id > 0 && (
                      <div class="form__group-body">
                        {loadingTickets && (
                          <div className="shadow">
                            <div className="shadow_circle">
                              <CircularProgress />
                            </div>
                          </div>
                        )}

                        {!loadingTickets && (
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <LabelHint
                              title="ticket_types"
                              hint="hint_ticket_types"
                            />
                            <div class="form__group-top">
                              <button
                                type="button"
                                class="form__btn"
                                onClick={self.addTicket}
                              >
                                <svg width="29" height="29">
                                  <use href="./img/sprite.svg#add-icon"></use>
                                </svg>
                                <span>{i18next.t("btn_add")}</span>
                              </button>
                            </div>
                          </div>
                        )}
                        {!loadingTickets &&
                          tickets_tmp.map(function (item, index) {
                            return (
                              <div class="form__group-rows">
                                <div class="form__group-row">
                                  <InputI18N
                                    type="text"
                                    onChange={(e, tabName) =>
                                      self.editTicketI18N(
                                        index,
                                        e.target.value,
                                        tabName
                                      )
                                    }
                                    class="form__input"
                                    placeholder={i18next.t("name")}
                                    value={
                                      isMultilang ? item.name_i18n : item.name
                                    }
                                    langList={langList}
                                    isMultilang={isMultilang}
                                  />
                                </div>
                                <div
                                  class="form__group-row"
                                  style={{ marginTop: isMultilang ? 45 : 0 }}
                                  onBlur={() => self.saveTicket(index)}
                                >
                                  {premiumState != "already" && (
                                    <input
                                      type="text"
                                      readonly="readonly"
                                      onClick={self.showTextForBuy}
                                      style={{ backgroundColor: "#D3D4D4" }}
                                      class="form__input"
                                      placeholder={i18next.t("price")}
                                      value={item.cost}
                                    />
                                  )}
                                  {premiumState == "already" && (
                                    <input
                                      type="text"
                                      class="form__input"
                                      placeholder={i18next.t("price")}
                                      value={item.cost}
                                      onChange={(e) =>
                                        self.editTicketPrice(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                </div>
                                <div
                                  style={{
                                    width: 30,
                                    marginTop: isMultilang ? 45 : 0,
                                  }}
                                >
                                  <SaveIcon
                                    onClick={() => self.saveTicket(index)}
                                    width="15"
                                    height="15"
                                  />
                                </div>

                                <div
                                  style={{
                                    width: 30,
                                    marginTop: isMultilang ? 45 : 0,
                                  }}
                                >
                                  <DeleteIcon
                                    onClick={() => self.delTicket(index)}
                                    width="15"
                                    height="15"
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                  <div
                    style={{ marginBottom: 10 }}
                    onClick={() =>
                      self.setState({
                        advancedSettingsIsOpen:
                          !this.state.advancedSettingsIsOpen,
                      })
                    }
                  >
                    <span>{i18next.t("advanced_settings")}</span>
                    {this.state.advancedSettingsIsOpen ? (
                      <ExpandLess fontSize="large" />
                    ) : (
                      <ExpandMore fontSize="large" />
                    )}
                  </div>

                  <Collapse
                    in={this.state.advancedSettingsIsOpen}
                    timeout="auto"
                  >
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <div className="form__group" style={{ marginRight: 20 }}>
                        <span className="form__label">
                          {i18next.t("image")}
                        </span>
                        <DropFile onDrop={this.onDrop} image={image} />
                      </div>

                      <div className="form__group" style={{ width: "100%" }}>
                        <LabelHint
                          title="org_contacts"
                          hint="hint_org_contacts"
                        />

                        <TextAreaI18N
                          id="area1"
                          className="form__input"
                          placeholder={i18next.t("text")}
                          name="contacts"
                          multiline
                          onChange={this.handleChangeI18N}
                          value={isMultilang ? contacts_i18n ?? {} : contacts}
                          langList={langList}
                          isMultilang={isMultilang}
                        />
                      </div>
                    </div>

                    <div className="form__group">
                      <LabelHint title="event_desc" hint="hint_event_desc" />
                      <TabEditor
                        onChange={this.descChange}
                        value={
                          isMultilang ? description_i18n ?? {} : description
                        }
                        langList={langList}
                        isMultilang={isMultilang}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <div className="form__group">
                          <FormGroup>
                            <div
                              style={{ flexDirection: "row", display: "flex" }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="default"
                                    checked={questionable == 1}
                                    onClick={() =>
                                      self.setState({
                                        questionable: 1 - questionable,
                                      })
                                    }
                                  />
                                }
                              />
                              <LabelHint
                                title="questions_enabled"
                                hint="hint_questions_enabled"
                              />
                            </div>
                            <div
                              style={{ flexDirection: "row", display: "flex" }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="default"
                                    checked={hidden_without_tickets == 1}
                                    onClick={() =>
                                      self.setState({
                                        hidden_without_tickets:
                                          1 - hidden_without_tickets,
                                      })
                                    }
                                  />
                                }
                              />
                              <LabelHint
                                title="hidden_without_tickets"
                                hint="hint_hidden_without_tickets"
                              />
                            </div>
                          </FormGroup>
                        </div>

                        <div className="form__group">
                          <div className="form__group-row">
                            <label htmlFor="text2" className="form__label">
                              {i18next.t("part_count")}: {part_count}
                            </label>
                          </div>

                          {id > 0 && (
                            <div class="form__group-top">
                              <span class="form__label">
                                {i18next.t("sponsors")}
                              </span>

                              <EditButton
                                onClick={self.editSponsors}
                                width="15"
                                height="15"
                              />
                            </div>
                          )}
                        </div>

                        {selectedConf && selectedConf.id > 0 && (
                          <div className="form__group">
                            <LabelHint
                              title="event_restrictions"
                              hint="hint_event_restrictions"
                            />
                            {this.state.confLimits}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div className="layout__block-btn">
                                <button
                                  className="btn btn--border"
                                  type="button"
                                  onClick={this.setPremium}
                                >
                                  {i18next.t("set_premium")}
                                </button>
                              </div>
                              <div style={{ marginLeft: 10 }}>
                                {i18next.t("premium")}: {premiumState}
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="form__group">
                          <LabelHint
                            title="event_lang"
                            hint="hint_event_lang"
                          />
                          <FormGroup>
                            {Object.keys(allLang).map((item) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="default"
                                    checked={langList.indexOf(item) >= 0}
                                    onClick={() => self.clickLangItem(item)}
                                  />
                                }
                                label={allLang[item]}
                              />
                            ))}
                          </FormGroup>
                        </div>

                        {0 > 1 && (
                          <div className="form__group">
                            <label htmlFor="area1" className="form__label">
                              {i18next.t("quest_and_answers")}
                            </label>

                            <TextAreaI18N
                              id="area1"
                              className="form__input"
                              placeholder={i18next.t("text")}
                              name="faq"
                              onChange={this.handleChangeI18N}
                              value={isMultilang ? faq_i18n ?? {} : faq}
                              langList={langList}
                              isMultilang={isMultilang}
                            />
                          </div>
                        )}
                      </div>
                      <div style={{ width: "50%" }}>
                        <div className="form__group">
                          <LabelHint
                            title="inet_on_site"
                            hint="hint_inet_on_site"
                          />

                          <textarea
                            id="area1"
                            className="form__input"
                            placeholder={i18next.t("text")}
                            name="wifi"
                            onChange={this.handleChange}
                            value={wifi}
                          ></textarea>
                        </div>
                        <div className="form__group">
                          <LabelHint
                            title="brief_for_speakers"
                            hint="hint_brief_for_speakers"
                          />
                          <textarea
                            id="area1"
                            className="form__input"
                            placeholder={i18next.t("text")}
                            name="brief"
                            onChange={this.handleChange}
                            value={brief}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="form__group">
                      <LabelHint
                        title="event_domain"
                        hint="event_domain_hint"
                      />
                      <TextField
                        variant="outlined"
                        title={i18next.t("event_domain")}
                        value={domain}
                        onChange={(e) =>
                          this.setState({ domain: e.target.value })
                        }
                      />
                    </div>
                    {domain && (
                      <div className="form__group">
                        <LabelHint
                          title="event_metrics"
                          hint="event_metrics_hint"
                        />
                        <textarea
                          variant="outlined"
                          title={i18next.t("event_metrics")}
                          value={metrics}
                          onChange={(e) =>
                            this.setState({ metrics: e.target.value })
                          }
                        />
                      </div>
                    )}
                    <ConferenceAnketa
                      requiredAnketa={this.state.requiredAnketa ?? []}
                      optionalAnketa={this.state.optionalAnketa ?? []}
                      allAnketaFields={this.state.allAnketaFields}
                      stateSetter={this.stateSetter}
                    />
                  </Collapse>
                </div>
              </div>
            </div>

            <div className="layout__col layout__col-aside">
              <div className="layout__block">
                <h2 className="layout__block-title">{i18next.t("preview")}</h2>

                <div className="layout__block-monitor">
                  <img
                    src={image ? serverUrl + image : confLogo}
                    alt=""
                    className="object-cover"
                  />
                  {format === "hybrid" && (
                    <span className="layout__block-label">
                      {i18next.t("hybrid_text")}
                    </span>
                  )}
                  {format === "online" && (
                    <span className="layout__block-label">
                      {i18next.t("online_text")}
                    </span>
                  )}
                  {format === "offline" && (
                    <span className="layout__block-label">
                      {i18next.t("offline_text")}
                    </span>
                  )}
                </div>

                <div className="layout__inf">
                  <h3 className="layout__inf-title">{name}</h3>
                  <div className="layout__inf">
                    <ul className="layout__inf-list">
                      {id > 0 && is_published > 0 && (
                        <a
                          href={conf_url}
                          target="_blank"
                          className="card__link"
                        >
                          <span>{i18next.t("btn_view")}</span>
                        </a>
                      )}
                      {(!id || !is_published) && (
                        <p className="card__link">
                          <span>{i18next.t("public_url")}</span>
                        </p>
                      )}
                    </ul>
                  </div>

                  <p className="layout__inf-text">
                    {start_date && start_time
                      ? start_date + " " + start_time
                      : i18next.t("undefined")}{" "}
                    {end_date && end_time
                      ? end_date + " " + end_time
                      : i18next.t("undefined")}
                  </p>
                  {selectedBldId && (
                    <p className="layout__inf-text">{bldAddr}</p>
                  )}
                  {!selectedBldId && (
                    <p className="layout__inf-text">{i18next.t("no_adress")}</p>
                  )}

                  <ul className="layout__inf-blocks">
                    <li className="layout__inf-block">
                      <div className="layout__inf-media">
                        <p className="layout__inf-contains">
                          <strong>{part_count}</strong> <br />
                          {i18next.t("part")}
                        </p>
                      </div>
                    </li>
                    <li className="layout__inf-block">
                      <div className="layout__inf-media">
                        <p className="layout__inf-contains">
                          <strong>{spons_count}</strong> <br />
                          {i18next.t("spons")}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="layout__block-btns">
                  <div className="layout__block-btn">
                    <button
                      className="btn btn--border"
                      type="button"
                      onClick={this.resetConf}
                    >
                      {i18next.t("reset")}
                    </button>
                  </div>

                  <div className="layout__block-btn">
                    {id == 0 && (
                      <button
                        onClick={this.onAdd}
                        className="btn"
                        type="submit"
                      >
                        {i18next.t("save_conf")}
                      </button>
                    )}
                    {id > 0 && (
                      <button
                        onClick={this.onSave}
                        className="btn"
                        type="submit"
                      >
                        {i18next.t("save_conf")}
                      </button>
                    )}
                  </div>
                  <div className="layout__block-btn">
                    <button
                      onClick={() => this.onPublic(1 - is_published)}
                      className={id > 0 ? "btn" : "btn_disabled"}
                      type="submit"
                      title={id > 0 ? "" : i18next.t("save_first")}
                    >
                      {is_published == 0
                        ? i18next.t("event_publish")
                        : i18next.t("event_disable_publish")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <BldDialog
          handleClose={self.closeBldDialog}
          id={bldId}
          dAddress={dAddress}
          dName={dName}
          dNameI18N={dNameI18N}
          dDesc={dDesc}
          dDescI18N={dDescI18N}
          dEmail={dEmail}
          dialogOpen={bldDialog}
          isMultilang={isMultilang}
          langList={langList}
        />

        <SponsorDialog
          handleClose={self.closeSpDialog}
          id={spId}
          sp_list={sponsors}
          all_list={sp_list}
          dialogOpen={spDialog}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedCreateConfPage = connect(mapStateToProps)(CreateConfPage);
export { connectedCreateConfPage as CreateConfPage };
