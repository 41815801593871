import React from "react";
import "../css/style.css";

import { CircularProgress } from "@material-ui/core";
import i18next from "i18next";
import { connect } from "react-redux";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer
} from "recharts";
import { ConferenceNotSelected } from "../_components/ConferenceNotSelected";
import { confService } from "../_services";

//const colors = ["#82ca9d", "#f4a582", "#b39ddb", "#ffee58", "#80deea"];
const colors = ["#82ca9d", "#f42582", "#b39ddb", "#eeaa58", "#80deea", "#ff8a65", "#ba68c8", "#4db6ac", "#7986cb", "#f06292"];

class StatPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      team: [],
      nameF: "",
      confF: "",
      ticketF: "",
      dateF: "",
      sortNameDesc: false,
      forStudentConference: 0,
      isOrg: false,
      dataByHours: [],
      daysList: [],
    };
  }

  componentDidMount() {

    this.getStatistics();
    this.setState({ isOrg: this.props.user.info.type === "org" });
  }

  onConfChange = () =>{
    this.getStatistics();
  }

  getStatistics = () => {
    const self = this;
    const { selectedConf } = this.props.user;

    if (this.props.user.info.type === "student" && !selectedConf) {
      self.setState({ team: [], isLoading: false, forStudentConference: 0 });
    } else {
      const id = selectedConf.id ?? 0;

      this.setState({isLoading:true});

      confService.getStatsParticipByDays(
        id,
        (data) => {
          console.log(data);

          self.setState({
            daysList: [...data.data.days].filter((item,index)=>index<10),
            dataByHours: [...data.data.graph],
            isLoading: false,
          });
        },
        (error) => {
          console.log(error);
          self.setState({isLoading:false})
        }
      );
    }
  };

  render() {
    const self = this;
    const { isLoading, forStudentConference, isOrg, dataByHours, daysList } =
      this.state;
    const forStudent = this.props.user.info.type === "student";

    console.log(dataByHours,daysList);
    

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">{i18next.t("statistics")}</h1>
        </div>
        
          <ConferenceNotSelected page={"statistics"} onChange={self.onConfChange}/>
         
        
        <ResponsiveContainer width={"90%"} height={800} style={{marginTop:10}}>
        <LineChart
          data={dataByHours}
          margin={{
            top: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          
            {daysList.map((item,index)=> <Line type="monotone" dataKey={item} stroke={colors[index % 10]} />)}
        </LineChart>
        </ResponsiveContainer>
        
        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedStatPage = connect(mapStateToProps)(StatPage);
export { connectedStatPage as StatPage };
